import React from 'react';

import { useInView } from 'react-intersection-observer';
import { trackEvent } from '../components/_tracking/Segment/Segment';

interface UseComponentTrackingHookResponse {
  ref: (node?: Element | null) => void;
}

const useComponentTracking = (
  trackingId: string,
  skip?: boolean,
  error?: string | boolean,
): UseComponentTrackingHookResponse => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    initialInView: false,
    delay: 1000,
  });

  React.useEffect(() => {
    if (inView) {
      trackEvent('component viewed', {
        trackingId,
        error: error || undefined,
      });
    }
  }, [inView, trackingId, error]);

  React.useEffect(() => {
    if (!skip) {
      trackEvent('component loaded', {
        trackingId,
        error: error || undefined,
      });
    }
  }, [skip, error, trackingId]);

  return {
    ref,
  };
};

export default useComponentTracking;
