import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';

import { User } from '../../api/user/types';
import { useUser } from '../../api/user/useUser';
import { PlatformConfig } from '../../config';
import { parseDate } from '../../utils/time';

declare global {
  interface Window {
    LaunchReview?: {
      launch: (success?: () => void, error?: () => void, appId?: string) => void;
      rating: (success?: () => void, error?: () => void) => void;
      isRatingSupported: () => boolean;
    };
  }
}

export interface InAppReviewLocalStorage {
  version: string;
  lastRequested?: string;
  interactions?: Record<string, number>;
}

interface UseInAppReview {
  requestReview: () => void;
  logInteraction: (name: string) => void;
}

const MS_PER_DAY = 1000 * 3600 * 24;

const VERSION = '1';
const REQUIRED_DAYS_SINCE_USER_CREATED = 30;
const REQUIRED_DAYS_SINCE_LAST_REQUESTED = 120; // iOS restricts to showing 3 times a year
const REQUIRED_DISTINCT_INTERACTIONS = 2;
const REQUIRED_TOTAL_INTERACTIONS = 2;

const DEFAULT_DATA = { version: VERSION };

export const isValid = (user: User | undefined, data: InAppReviewLocalStorage | undefined): boolean => {
  if (!user || !data) {
    return false;
  }

  const createdAt = new Date(user.createdAt);
  const lastRequested = parseDate(data.lastRequested);
  const { interactions } = data;
  const nowMilli = new Date().getTime();

  const daysSinceUserCreated = (nowMilli - createdAt.getTime()) / MS_PER_DAY;
  if (daysSinceUserCreated < REQUIRED_DAYS_SINCE_USER_CREATED) {
    return false;
  }

  const daysSinceLastRequested = lastRequested ? (nowMilli - lastRequested.getTime()) / MS_PER_DAY : null;
  if (daysSinceLastRequested && daysSinceLastRequested < REQUIRED_DAYS_SINCE_LAST_REQUESTED) {
    return false;
  }

  const distinctInteractions = interactions ? Object.keys(interactions).length : 0;
  if (distinctInteractions < REQUIRED_DISTINCT_INTERACTIONS) {
    return false;
  }

  const totalInteractions = interactions
    ? Object.values(interactions).reduce((total, current) => total + current)
    : 0;
  if (totalInteractions < REQUIRED_TOTAL_INTERACTIONS) {
    return false;
  }

  return true;
};

const useInAppReview = (listen?: boolean): UseInAppReview => {
  const isEnabled = PlatformConfig.isNative && Boolean(window.LaunchReview);
  const { user } = useUser();
  const [data, setData] = useLocalStorage<InAppReviewLocalStorage | undefined>(
    'in-app-review',
    isEnabled ? DEFAULT_DATA : undefined,
  );
  const { version, interactions } = data || {};
  const [shouldRequestReview, setShouldRequestReview] = React.useState<boolean>(false);

  const requestReview = React.useCallback(() => {
    if (window.LaunchReview && isValid(user, data)) {
      if (window.LaunchReview.isRatingSupported()) {
        window.LaunchReview.rating();
      } else {
        window.LaunchReview.launch();
      }
      setData({ ...(data || DEFAULT_DATA), lastRequested: new Date().toString() });
    }
  }, [data, setData, user]);

  const logInteraction = (name: string) => {
    if (isEnabled && data) {
      const updatedInteractions: Record<string, number> = { ...interactions };
      updatedInteractions[name] = updatedInteractions[name] + 1 || 1;
      setData({
        ...data,
        interactions: updatedInteractions,
      });
    }
  };

  // listen for app returning to foreground
  React.useEffect(() => {
    if (isEnabled && listen) {
      document.addEventListener('resume', () => setShouldRequestReview(true), false);
    }
    return () => {
      document.removeEventListener('resume', () => setShouldRequestReview(true), false);
    };
  }, [isEnabled, listen]);

  // request review when app has returned to foreground
  React.useEffect(() => {
    if (shouldRequestReview) {
      requestReview();
      setShouldRequestReview(false);
    }
  }, [requestReview, shouldRequestReview]);

  // clear local storage if version has changed
  React.useEffect(() => {
    if (version && version !== VERSION) {
      setData(DEFAULT_DATA);
    }
  }, [setData, version]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    requestReview,
    logInteraction,
  };
};

export default useInAppReview;
