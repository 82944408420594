import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getFriendlyError } from '../../utils/error';

import { CustomerTerms, getCustomerTerms } from './customerTerms';

export interface UseCustomerTerms {
  data?: CustomerTerms;
  isFetched: boolean;
  isLoading: boolean;
  error?: string;
}

export const useCustomerTerms = (termName: string): UseCustomerTerms => {
  const { data, isFetched, isLoading, error } = useQuery<CustomerTerms, AxiosError, CustomerTerms>(
    ['customerTerms', termName],
    () => getCustomerTerms(termName),
    {
      enabled: Boolean(termName),
    },
  );

  return {
    data,
    isFetched,
    isLoading,
    error: getFriendlyError(error, 'customer terms'),
  };
};

export default useCustomerTerms;
