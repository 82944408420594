import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import Message from 'components/Message/Message';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import { ROUTE } from '../../routes';

import styles from './ErrorMessage.module.scss';

export interface Props {
  className?: string;
  error?: string | undefined;
  hideSupportLink?: boolean;
  scrollIntoViewOnError?: boolean;
  textWithLink?: string;
}

const ErrorMessage: React.FC<Props> = (props: Props) => {
  const { className, error, hideSupportLink = false, scrollIntoViewOnError = false, textWithLink } = props;

  const messageRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const localePrefix = 'errorMessage';

  useEffect(() => {
    if (error && scrollIntoViewOnError) {
      messageRef.current?.scrollIntoView();
    }
  }, [error, scrollIntoViewOnError]);

  if (error || textWithLink) {
    const message = (
      <Message title={error} variant="warning" className={classNames(styles.errorMessage, className)}>
        {textWithLink && (
          <h4
            dangerouslySetInnerHTML={{
              __html: t(textWithLink),
            }}
          />
        )}
        {!hideSupportLink && !textWithLink && (
          <p
            dangerouslySetInnerHTML={{
              __html: t(`${localePrefix}.ifProblemPersists`, {
                supportHref: ROUTE.SUPPORT,
              }),
            }}
          />
        )}
      </Message>
    );
    if (scrollIntoViewOnError) {
      return <div ref={messageRef}>{message}</div>;
    }
    return message;
  }
  return null;
};

export default ErrorMessage;
