import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom';

import './i18n';

import './theme/app.css'; // must be imported before App

import { bootIntercom } from './components/IntercomBubble/IntercomBubble';
import App from './components/_app/App/App';
import * as serviceWorker from './serviceWorker';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: String(process.env.VITE_LAUNCH_DARKLY_CLIENT_ID),
    options: {
      bootstrap: 'localStorage',
    },
    user: {
      anonymous: true,
      key: 'unknown-user-key',
    },
  });
  bootIntercom();
  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
