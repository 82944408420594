import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useUser } from '../../api/user/useUser';
import { getFriendlyError } from '../../utils/error';

import { postTermsAgreement, validateTermAgreement } from './customerTerms';

const QUERY_KEY = 'validateTermsAgreement';

export interface UseTermsAgreement {
  isValidTerm?: boolean;
  isValidateFetched: boolean;
  isValidateLoading: boolean;
  isPostLoading: boolean;
  showTermsAgreement: boolean;
  validateError?: string;
  createTermsAgreement: () => void;
}

export const useTermsAgreement = (termName: string, termsId?: string): UseTermsAgreement => {
  const { isAuthenticated, isImpersonatedUser } = useUser();
  const queryClient = useQueryClient();

  const {
    mutate: createTermsAgreement,
    isLoading: isPostLoading,
    error,
  } = useMutation<void, AxiosError, void>(
    ['postTermsAgreement', termsId, isAuthenticated],
    () => postTermsAgreement(termsId ?? ''),
    {
      onSuccess: () => queryClient.invalidateQueries(QUERY_KEY),
    },
  );

  const {
    data: isValidTerm,
    isFetched: isValidateFetched,
    isLoading: isValidateLoading,
    error: validateError,
  } = useQuery<boolean, AxiosError, boolean>(
    [QUERY_KEY, termName, isAuthenticated],
    () => validateTermAgreement(termName),
    {
      enabled: isAuthenticated && Boolean(termName),
    },
  );

  return {
    createTermsAgreement,
    isValidTerm,
    isValidateFetched,
    isValidateLoading,
    isPostLoading,
    showTermsAgreement: isValidateFetched && !isValidTerm && !validateError && !isImpersonatedUser(),
    validateError: getFriendlyError(error || validateError, 'terms agreement'),
  };
};

export default useTermsAgreement;
