import { Spinner } from '@palmetto/palmetto-components';
import classNames from 'classnames';
import React, { useContext } from 'react';

import useDebug from 'hooks/useDebug';
import { PlatformConfig } from '../../config';

import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import styles from './LoadingPage.module.scss';

export interface Props {
  message?: string;
}

const LoadingPage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.loading, { [styles.chromatic]: PlatformConfig.isChromatic })}>
      <div className={styles.backdrop} />
      <div className={styles.wrapper}>
        <Spinner className={styles.spinner} color="primary" />
        <span>{message || t('loading')}</span>
      </div>
    </div>
  );
};

const LoadingPageContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  message: string | undefined;
  setMessage: (message: string | undefined) => void;
}>({
  isOpen: false,
  setIsOpen: () => {
    /* no-op */
  },
  message: undefined,
  setMessage: () => {
    /* no-op */
  },
});

const GlobalLoadingPage: React.FC = () => {
  const { isOpen, message } = useContext(LoadingPageContext);
  const debug = useDebug();
  if (isOpen) {
    debug('render GlobalLoadingPage as LoadingPage');
    return <LoadingPage message={message} />;
  }
  debug('render GlobalLoadingPage as null');
  return null;
};

interface LoadingPageProviderProps {
  children: React.ReactNode;
}

export const LoadingPageProvider: React.FC<LoadingPageProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | undefined>(undefined);

  const timerRef = React.useRef<number | NodeJS.Timeout | null>(null);
  const isOpenRef = React.useRef<boolean>(false);

  const debouncedSetIsOpen = React.useCallback((open: boolean) => {
    isOpenRef.current = open;
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      setIsOpen(isOpenRef.current);
    }, 200);

    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  const value = React.useMemo(
    () => ({
      isOpen,
      setIsOpen: debouncedSetIsOpen,
      message,
      setMessage,
    }),
    [debouncedSetIsOpen, isOpen, message],
  );

  return (
    <LoadingPageContext.Provider value={value}>
      {children}
      <GlobalLoadingPage />
    </LoadingPageContext.Provider>
  );
};

const LocalLoadingPage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const { setIsOpen, setMessage } = useContext(LoadingPageContext);

  React.useEffect(() => {
    setIsOpen(true);
    setMessage(message);
    return () => {
      // clear on unmount
      setIsOpen(false);
      setMessage(undefined);
    };
  }, [message, setIsOpen, setMessage]);

  return null;
};

export default React.memo(LocalLoadingPage);
