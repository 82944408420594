import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';

import PageNotFound from 'pages/PageNotFound/PageNotFound';
import { FeatureConfig } from '../../config';
import { ROUTE } from '../../routes';
import LoadingPage from '../LoadingPage/LoadingPage';
import { ONBOARDING_FEED_ITEM_ROUTES } from '../Routes/OnboardingFeedItems';
import Route from '../Routes/Route';

import FinanceAccountsList from 'pages/PaymentsV3/FinanceAccountsList/FinanceAccountsList';
import { RouteConfig } from './types';

const About = lazy(() => import('../ProductionConsumptionWidget/AboutModal/AboutModal'));
const Auth0Landing = lazy(() => import('../../pages/Auth0/Auth0'));
const BuildingAttributeUpdate = lazy(() => import('../../pages/BuildingAttributeUpdate/BuildingAttributeUpdate'));

const Dashboard = lazy(() => import('../../pages/Dashboard/Dashboard'));
const DeepLink = lazy(() => import('../../pages/DeepLink/DeepLink'));
const EnergyAdvisor = lazy(() => import('../../pages/EnergyAdvisor/EnergyAdvisor'));
const EnergyProfile = lazy(() => import('../../pages/EnergyProfile/EnergyProfile'));
const FinanceAccountDetail = lazy(() => import('../../pages/PaymentsV3/FinanceAccountDetail/FinanceAccountDetail'));

const Lander = lazy(() => import('../../pages/Lander/Lander'));
const Logout = lazy(() => import('../../pages/Logout/Logout'));
const PanelPlacement = lazy(() => import('../PanelPlacement/PanelPlacement'));
const PasswordReset = lazy(() => import('../../pages/PasswordReset/PasswordReset'));
const PaymentSetupV3 = lazy(() => import('../../pages/PaymentsV3/PaymentSetup/PaymentSetup'));
const Profile = lazy(() => import('../../pages/Profile/Profile'));
const ProjectDetail = lazy(() => import('../../pages/ProjectDetail/ProjectDetail'));
const PhsyicalSiteSurveyAvailability = lazy(
  () => import('../../pages/PhysicalSiteSurveyAvailability/PhysicalSiteSurveyAvailability'),
);
const Questionnaire = lazy(() => import('../../pages/Questionnaire/Questionnaire'));
const ReferAFriend = lazy(() => import('../../pages/ReferAFriend/ReferAFriend'));
const Service = lazy(() => import('../../pages/Service/Service'));
const Signup = lazy(() => import('../../pages/Signup/Signup'));
const Support = lazy(() => import('../../pages/Support/Support'));
const UploadUtilityBill = lazy(() => import('../../pages/UploadUtilityBill/UploadUtilityBill'));
const UtilityProvider = lazy(() => import('../../pages/UtilityProvider/UtilityProvider'));
const UtilityRatePlanFlowLogin = lazy(() => import('../../pages/UtilityRatePlan/FlowLogin'));
const Products = lazy(() => import('../../pages/Products/Products'));

export const ROUTES: RouteConfig[] = [
  { path: ROUTE.DEEPLINK, isPublic: true, component: DeepLink },
  {
    path: ROUTE.HOME,
    exact: true,
    isPublic: true,
    component: Lander,
  },
  { path: ROUTE.AUTH0, exact: true, isPublic: true, component: Auth0Landing },
  {
    path: ROUTE.PASSWORD_RESET,
    exact: true,
    component: PasswordReset,
    isPublic: true,
  },
  {
    path: ROUTE.SIGNUP,
    isPublic: true,
    exact: true,
    component: Signup,
  },
  { path: ROUTE.LOGOUT, isPublic: false, exact: true, component: Logout },
  {
    path: ROUTE.DASHBOARD,
    exact: true,
    component: Dashboard,
  },
  {
    path: ROUTE.DASHBOARD_ABOUT,
    exact: true,
    component: About,
    headerProps: {
      pageTitle: 'about.pageTitle',
    },
  },
  {
    path: ROUTE.SERVICE,
    component: Service,
    headerProps: {
      pageTitle: 'service.pageTitle',
    },
  },
  {
    path: ROUTE.SUPPORT,
    isPublic: true,
    exact: true,
    component: Support,
    headerProps: {
      pageTitle: 'support.pageTitle',
    },
  },
  {
    path: ROUTE.MOCK,
    isPublic: true,
  },
  {
    path: ROUTE.PANEL_PLACEMENT,
    component: PanelPlacement,
    isPublic: true,
    headerProps: {
      pageTitle: 'panelPlacement.pageTitle',
    },
  },
  {
    path: ROUTE.FINANCE_ACCOUNTS,
    exact: true,
    component: FinanceAccountsList,
    headerProps: {
      pageTitle: 'payments.pageTitle',
    },
  },
  {
    path: ROUTE.FINANCE_ACCOUNTS_DETAIL,
    exact: true,
    component: FinanceAccountDetail,
    headerProps: {
      pageTitle: 'payments.pageTitle',
    },
  },
  {
    path: ROUTE.PAYMENT_SETUP,
    exact: true,
    component: PaymentSetupV3,
    headerProps: {
      pageTitle: 'paymentsV3.pageTitle',
    },
  },
  {
    path: ROUTE.PROFILE,
    exact: true,
    component: Profile,
    headerProps: {
      pageTitle: 'profile.pageTitle',
    },
  },
  {
    path: ROUTE.REFER,
    exact: true,
    component: ReferAFriend,
    featureConfig: FeatureConfig.referAFriend,
    headerProps: {
      pageTitle: 'refer.pageTitle',
    },
  },
  {
    path: ROUTE.ENERGY_ADVISOR,
    exact: true,
    component: EnergyAdvisor,
    featureConfig: FeatureConfig.energyAdvisorPage,
    headerProps: {
      pageTitle: 'energyAdvisor.pageTitle',
    },
  },
  {
    path: ROUTE.QUESTIONNAIRE,
    component: Questionnaire,
  },
  {
    path: ROUTE.PHYSICAL_SITE_SURVEY_AVAILABILITY,
    exact: true,
    component: PhsyicalSiteSurveyAvailability,
  },
  {
    path: ROUTE.ENERGY_PROFILE,
    exact: true,
    component: EnergyProfile,
    featureConfig: FeatureConfig.energyProfile,
    headerProps: {
      pageTitle: 'energyProfile.pageTitle',
    },
  },
  {
    path: ROUTE.PROPERTIES_BUILDING_ATTRIBUTE_UPDATE,
    exact: true,
    component: BuildingAttributeUpdate,
    featureConfig: FeatureConfig.energyProfile,
    headerProps: {
      pageTitle: 'energyProfile.pageTitle',
    },
  },
  {
    path: ROUTE.UTILITY_PROVIDER,
    exact: true,
    component: UtilityProvider,
    headerProps: {
      pageTitle: 'utilityProvider.pageTitle',
    },
    featureConfig: FeatureConfig.utilityProvider,
  },
  {
    path: ROUTE.UTILITY_BILL_UPLOAD,
    exact: true,
    component: UploadUtilityBill,
    headerProps: {
      pageTitle: 'utilityBill.pageTitle',
    },
  },
  {
    path: ROUTE.UTILITY_RATE_PLAN_ENROLLMENT,
    exact: true,
    render: (props) => {
      const params = new URLSearchParams(props.location.search);
      const returnUrl = params.get('returnUrl') || ROUTE.DASHBOARD;
      return <UtilityRatePlanFlowLogin {...props} scope="enrollment" returnUrl={returnUrl} />;
    },
    headerProps: {
      pageTitle: 'utilityRatePlan.enrollment.pageTitle',
    },
  },
  {
    path: ROUTE.FINANCE_ACCOUNTS_UPDATE_PAYMENT_METHOD,
    exact: true,
    render: ({ match, ...props }) => (
      <UtilityRatePlanFlowLogin
        {...props}
        scope="update-payment-method"
        returnUrl={`/finance-accounts/${match.params.financeAccountId}`}
      />
    ),
    headerProps: {
      pageTitle: 'utilityRatePlan.updatePaymentMethod.pageTitle',
    },
  },
  {
    path: ROUTE.PROJECT_DETAIL,
    exact: true,
    component: ProjectDetail,
  },
  {
    path: ROUTE.PRODUCTS,
    headerProps: {
      hideHeader: true,
    },
    hideBackgroundImage: true,
    render: ({ match }) => {
      if (match.params.category) {
        return <Products category={match.params.category} />;
      }
      return <PageNotFound />;
    },
  },
  ...ONBOARDING_FEED_ITEM_ROUTES,
];
interface Props {
  routes: RouteConfig[];
}

export const RouteConfigSwitch: React.FC<Props> = (props: Props) => {
  const { routes } = props;
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Redirect from={ROUTE.LEGACY_ACCOUNT} to={pathname.replace(ROUTE.LEGACY_ACCOUNT, '')} />
        {routes?.map((route: RouteConfig) => {
          return <Route key={String(route.path)} {...route} />;
        })}
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

const Routes: React.FC = () => {
  return <RouteConfigSwitch routes={ROUTES} />;
};
export default Routes;
