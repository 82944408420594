import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { useNavigation } from 'hooks/useNavigation';
import { trackClick } from '../_tracking/Segment/Segment';

import styles from './Link.module.scss';

export type LinkProps = {
  to: string;
  trackingId: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  variant?: 'standalone' | 'standalone-gray';
  ariaLabel?: string;
};

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const { to, trackingId, className, children, onClick, variant, target, ariaLabel } = props;
  const { openURL } = useNavigation();
  return (
    <a
      className={classNames(className, {
        [styles.standaloneGray]: variant === 'standalone-gray',
      })}
      href={to}
      target={target}
      onClick={(e) => {
        trackClick(props.trackingId, 'link', {
          href: props.to,
          page: window.location.href,
        });
        if (onClick) {
          onClick();
        }
        if (to) {
          if (to.indexOf('tel:') === 0 || to.indexOf('mailto:') === 0) {
            return;
          }
          e.preventDefault();
          openURL(to);
        }
      }}
      data-tracking-id={trackingId}
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
};

export default Link;
