import { datadogRum } from '@datadog/browser-rum';
import { capitalize } from 'lodash';

export enum AssociatedEntityTypes {
  epc = 'epc',
  alchemySalesRep = 'alchemySalesRep',
  alchemyCustomerLead = 'alchemyCustomerLead',
  sabalOrganization = 'sabalOrganization',
  luminanceEstimate = 'luminanceEstimate',
  sabalAccount = 'sabalAccount',
}

export interface AssociatedEntity {
  entityType: AssociatedEntityTypes;
  id: string;
}

export interface AssociatedEntities {
  associatedEntities: AssociatedEntity[];
}

export type AssociatedEntityRecord = null | AssociatedEntity[] | Partial<AssociatedEntities>;

export type AssociatedEntityLookup = (record?: AssociatedEntityRecord) => AssociatedEntityLookupFunctions;

export type AssociatedEntityIdGetter = () => string | null;
export type AssociatedEntityIdOrThrowGetter = () => string;
export type AssociatedEntityIdsGetter = () => string[];

export type AssociatedEntityIdGetters = {
  [AE in AssociatedEntityTypes as `${AE}Id`]: AssociatedEntityIdGetter;
};
export type AssociatedEntityIdOrThrowGetters = {
  [AE in AssociatedEntityTypes as `${AE}IdOrThrow`]: AssociatedEntityIdOrThrowGetter;
};
export type AssociatedEntityIdsGetters = {
  [AE in AssociatedEntityTypes as `all${Capitalize<AE>}Ids`]: AssociatedEntityIdsGetter;
};

export type AssociatedEntityLookupFunctions = AssociatedEntityIdGetters &
  AssociatedEntityIdOrThrowGetters &
  AssociatedEntityIdsGetters & {
    contains: (associatedEntityType: AssociatedEntityTypes) => boolean;
    get: (associatedEntityType: AssociatedEntityTypes) => AssociatedEntity | null;
    getEntities: (associatedEntityType?: AssociatedEntityTypes) => AssociatedEntity[];
    getId: (associatedEntityType: AssociatedEntityTypes) => string | null;
    getIdOrThrow: (associatedEntityType: AssociatedEntityTypes) => string;
    getIds: (associatedEntityType: AssociatedEntityTypes) => string[];
  };

export type AssociatedEntityGetterFactory<K> = () => Record<string & keyof K, K[keyof K]>;

export const associatedEntityLookup: AssociatedEntityLookup = (record) => {
  const entities = Array.isArray(record) ? record : record?.associatedEntities || [];

  const contains = (associatedEntityType: AssociatedEntityTypes): boolean => {
    return Boolean(get(associatedEntityType)?.id);
  };

  const get = (associatedEntityType: AssociatedEntityTypes): AssociatedEntity | null => {
    return entities.find((e) => e?.entityType === associatedEntityType) || null;
  };

  const getEntities = (associatedEntityType?: AssociatedEntityTypes): AssociatedEntity[] => {
    if (!associatedEntityType) {
      return entities;
    }
    return entities.filter((e) => e?.entityType === associatedEntityType);
  };

  const getId = (associatedEntityType: AssociatedEntityTypes): string | null => {
    const id = get(associatedEntityType)?.id;
    return id || null;
  };

  const getIdOrThrow = (associatedEntityType: AssociatedEntityTypes): string => {
    const id = getId(associatedEntityType);
    if (!id) {
      datadogRum.addError(`${associatedEntityLookup.name} - unable to get entity ${associatedEntityType}`);
      throw new Error(`${associatedEntityLookup.name} - unable to get entity ${associatedEntityType}`);
    }
    return id;
  };

  const getIds = (associatedEntityType: AssociatedEntityTypes): string[] => {
    return getEntities(associatedEntityType).flatMap((ae) => (ae?.id ? [ae.id] : []));
  };

  const idGetterFactory: AssociatedEntityGetterFactory<AssociatedEntityIdGetters> = () => {
    return Object.values(AssociatedEntityTypes).reduce((getters, ae) => {
      return { ...getters, [`${ae}Id`]: () => getId(ae) };
    }, {} as Record<string, AssociatedEntityIdGetter>);
  };

  const idOrThrowGetterFactory: AssociatedEntityGetterFactory<AssociatedEntityIdOrThrowGetters> = () => {
    return Object.values(AssociatedEntityTypes).reduce((getters, ae) => {
      return { ...getters, [`${ae}IdOrThrow`]: () => getIdOrThrow(ae) };
    }, {} as Record<string, AssociatedEntityIdOrThrowGetter>);
  };

  const idsGetterFactory: AssociatedEntityGetterFactory<AssociatedEntityIdsGetters> = () => {
    return Object.values(AssociatedEntityTypes).reduce((getters, ae) => {
      return { ...getters, [`all${capitalize(ae)}Ids`]: () => getIds(ae) };
    }, {} as Record<string, AssociatedEntityIdsGetter>);
  };

  return {
    ...idGetterFactory(),
    ...idOrThrowGetterFactory(),
    ...idsGetterFactory(),
    contains,
    get,
    getEntities,
    getId,
    getIdOrThrow,
    getIds,
  };
};
