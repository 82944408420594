import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import Message from 'components/Message/Message';
import Page from 'components/Page/Page';
import React from 'react';
import { formatPhone, PHONE_SUPPORT } from 'utils/contact';
import { PlatformConfig } from '../../config';
import { ROUTE } from '../../routes';
import styles from './PageError.module.scss';

export interface Props {
  error: Error;
}

const PageError: React.FC<Props> = (props: Props) => {
  const { error } = props;
  const [clicks, setClicks] = React.useState<number>(0);
  const { t } = useTranslation();
  const localePrefix = 'pageError';
  const showError = clicks >= 3;
  return (
    <Page layout="singleItem">
      <Card maxWidth="4xl" padding="lg" className={styles.pageError}>
        <Button
          isNaked
          className={styles.clickable}
          onClick={() => setClicks(clicks + 1)}
          fullWidth
          trackingId="page-error-debugger"
        >
          <h1>{t(`${localePrefix}.error`)}</h1>
          <p>{t(`${localePrefix}.errorMessage`)}</p>
        </Button>
        <div>
          <Button
            className={styles.button}
            onClick={() => {
              // Event Listener for history.back()
              window.onpopstate = () => {
                window.location.reload();
              };
              window.history.back();
            }}
            trackingId="page-error-back"
          >
            {t('common.goBack')}
          </Button>
          <Button
            className={styles.button}
            onClick={() => {
              window.location.reload();
            }}
            trackingId="page-error-reload"
          >
            {t(`${localePrefix}.reload`)}
          </Button>
        </div>

        <div className={styles.support}>
          <a href={ROUTE.SUPPORT}>{t(`${localePrefix}.connectWithCustomerSupport`)}</a>
          <div className={styles.call}>
            {t(`${localePrefix}.callUs`)} <a href={`tel:+${PHONE_SUPPORT}`}>{formatPhone(PHONE_SUPPORT)}</a>
          </div>
        </div>
        {showError && (
          <Message className={styles.error} color="red" title={error.message}>
            {PlatformConfig.isTestRun || PlatformConfig.isStorybook ? `${error.message} stack trace` : error.stack}
          </Message>
        )}
      </Card>
    </Page>
  );
};

export default PageError;
