import { IonSpinner } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import { PlatformConfig } from '../../config';
import styles from './LoadingContent.module.scss';

export interface Props {
  className?: string;
}

const LoadingContent: React.FC<Props> = ({ className }: Props) => (
  <div className={classNames(styles.loading, className)}>
    <IonSpinner color="primary" name="dots" aria-label="loading" paused={PlatformConfig.isChromatic} />
  </div>
);

export default LoadingContent;
