import useProperties from 'api/properties/useProperties';
import { useUser } from 'api/user/useUser';
import useDebug from 'hooks/useDebug';
import { LOGIN_REDIRECT_KEY } from 'hooks/useLoginRedirect';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { Redirect } from 'react-router';
import { Route as ReactRoute } from 'react-router-dom';
import { ROUTE } from '../../routes';
import LoadingPage from '../LoadingPage/LoadingPage';
import { RouteConfig } from './types';

interface Props extends RouteConfig {
  key?: string;
}

const Route: React.FC<Props> = (props: Props) => {
  const { isPublic, featureConfig, location, alternatePageConfig, alternatePage, ...restProps } = props;
  const { isLoading, isIdentifying, user, isAuthenticated } = useUser();
  const { data: property } = useProperties(user?.propertyIds?.[0]);
  const flags = useFlags();
  const debug = useDebug();

  debug('render Route', { props, isUserLoading: isLoading, isUserIdentifying: isIdentifying });
  if (isLoading || isIdentifying) {
    debug('render Route as LoadingPage');
    return <LoadingPage />;
  }

  if ((!isPublic && !isAuthenticated) || (featureConfig && !featureConfig(user, flags, property))) {
    if (!isAuthenticated && location?.pathname && location.pathname !== ROUTE.LOGOUT) {
      window.localStorage.setItem(LOGIN_REDIRECT_KEY, `${location.pathname}${location.search}`); // This enables a redirect back to the requested page when a user logs back in
    }
    debug(`render Route as Redirect to ${ROUTE.HOME}`);
    return <Redirect to={ROUTE.HOME} />;
  }

  if (alternatePageConfig && alternatePageConfig(user, flags)) {
    restProps.component = alternatePage;
  }

  debug(`render Route as ReactRoute`);
  return <ReactRoute {...restProps} />;
};

export default Route;
