import React from 'react';

import { useUser } from '../api/user/useUser';
import { useNavigation } from '../hooks/useNavigation';

export const LOGIN_REDIRECT_KEY = 'loginRedirect';

const useLoginRedirect = (): void => {
  const { isAuthenticated } = useUser();
  const { openURL } = useNavigation();
  const loginRedirect = window.localStorage.getItem(LOGIN_REDIRECT_KEY);
  React.useEffect(() => {
    if (isAuthenticated && loginRedirect) {
      window.localStorage.removeItem(LOGIN_REDIRECT_KEY);
      if (loginRedirect.indexOf('/') === 0) {
        openURL(loginRedirect);
      }
    }
  }, [isAuthenticated, loginRedirect, openURL]);
};

export default useLoginRedirect;
