import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import request from '../api';
import { PlatformConfig } from '../config';
import { readFileAsDataUrl } from './readFileAsDataUrl';

export async function fetchAndOpenDocument(url: string, fileName: string) {
  try {
    const result = await request({
      url,
      responseType: 'blob',
    });
    const blob = new Blob([result.data], { type: result.headers['content-type'] || 'application/pdf' });

    if (!PlatformConfig.isNative) {
      const documentUrl = URL.createObjectURL(blob);
      window.open(documentUrl, '_blank');
      return;
    }

    const data = await readFileAsDataUrl(blob);
    if (!data) {
      throw new Error('Failed to read file as data URL');
    }

    const writeFileResult = await Filesystem.writeFile({
      path: fileName,
      data: data.toString(),
      directory: Directory.Cache,
    });

    await FileOpener.openFile({ path: writeFileResult.uri });
  } catch (error) {
    throw new Error(`Failed to fetch and open document: ${error}`);
  }
}
