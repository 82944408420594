import classNames from 'classnames';

import Card from 'components/Card/Card';
import Link from 'components/Link/Link';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import useComponentTracking from 'hooks/useComponentTracking';

import Button from '../Button/Button';
import styles from './LinksCard.module.scss';

export interface CardLinkBase {
  trackingId: string;
  text: string;
}

export interface CardLinkWithHref extends CardLinkBase {
  href: string;
  onClick?: never;
}

export interface CardLinkWithOnClick extends CardLinkBase {
  href?: never;
  onClick: () => void;
}

export type CardLink = CardLinkWithHref | CardLinkWithOnClick;

export interface LinksCardProps {
  superscript?: string;
  trackingId: string;
  links: CardLink[];
  className?: string;
}

const LinksCard = (props: LinksCardProps) => {
  const { t } = useTranslation();
  const { superscript = t('linksCard.defaultSuperscript'), trackingId: cardTrackingId, links, className } = props;

  useComponentTracking(cardTrackingId);

  return (
    <Card className={classNames(styles.card, className)} semiTransparentBg>
      <h2 className={styles.superscript}>{superscript}</h2>
      {links.map((link) => {
        const key = `${cardTrackingId}-${link.trackingId}`;

        if (link.onClick) {
          return (
            <Button
              key={key}
              type="button"
              className={styles.button}
              onClick={link.onClick}
              trackingId={key}
              variant="text"
            >
              {link.text}
            </Button>
          );
        }

        return (
          <Link key={key} className={styles.link} to={link.href} trackingId={key} variant="standalone">
            {link.text}
          </Link>
        );
      })}
    </Card>
  );
};

export default LinksCard;
