import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useUser } from '../../api/user/useUser';
import { trackEvent } from '../../components/_tracking/Segment/Segment';
import { PlatformConfig } from '../../config';

export const usePushNotifications = () => {
  const { isAuthenticated, updateUser, user } = useUser();
  const history = useHistory();

  const register = useCallback(() => {
    PushNotifications.removeAllListeners();

    PushNotifications.register();

    PushNotifications.addListener('registration', (token: Token) => {
      trackEvent('Push Notifications Registered', {
        context: {
          device: {
            token: token.value,
            type: PlatformConfig.platform,
          },
        },
      });

      updateUser({ fcmToken: token.value });
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: RegistrationError) => {
      datadogRum.addError('Push Notification Registration Error', error);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      trackEvent('Push Notification Received', {
        ...notification.data,
      });
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      if (action.actionId === 'tap') {
        trackEvent('Push Notification Opened', {
          ...action.notification.data,
        });

        history.push(action.notification.data.path);
      } else if (action.actionId === 'dismiss') {
        trackEvent('Push Notification Dismissed', {
          ...action.notification.data,
        });
      }
    });
  }, [updateUser]);

  useEffect(() => {
    if (isAuthenticated && Capacitor.isPluginAvailable('PushNotifications') && user?._id) {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then(() => {
            if (res.receive === 'denied') {
              trackEvent('Notifications Denied');
            } else {
              trackEvent('Notifications Accepted');
            }
          });
        }
      });

      register();
    }
  }, [isAuthenticated, register, user?._id]);
};
