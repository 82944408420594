import { App as CapApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../api/user/useUser';
import { trackEvent } from '../../components/_tracking/Segment/Segment';

export const checkForAppUpdate = async (): Promise<void> => {
  if (localStorage.shouldReloadApp === 'true') {
    await LiveUpdates.reload();
  } else if (Capacitor.isNativePlatform()) {
    const result = await LiveUpdates.sync();
    localStorage.shouldReloadApp = result.activeApplicationPathChanged;
  }
};

const useNativeAppListener = (): void => {
  const history = useHistory();
  const { user, isAuthenticated } = useUser();

  useEffect(() => {
    const initialSync = async () => {
      if (Capacitor.isNativePlatform()) {
        const result = await LiveUpdates.sync();
        localStorage.shouldReloadApp = result.activeApplicationPathChanged;
        if (result.activeApplicationPathChanged && result.source === 'cache') {
          await LiveUpdates.reload();
        }
      }
    };
    initialSync();
  }, []);

  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data) => {
      const { pathname, search } = new URL(data.url);
      if (pathname) {
        history.push(`${pathname}${search}`);
      }
    });
  });

  useEffect(() => {
    const handleResume = async () => {
      if (isAuthenticated && user?._id) {
        trackEvent('Application Opened');
      }
      await checkForAppUpdate();
    };
    const resumeListener = CapApp.addListener('resume', handleResume);

    return () => {
      resumeListener.then((listener) => listener.remove());
    };
  }, [isAuthenticated, user?._id]);

  useEffect(() => {
    const handlePause = async () => {
      await checkForAppUpdate();
    };
    const pauseListener = CapApp.addListener('pause', handlePause);

    return () => {
      pauseListener.then((listener) => listener.remove());
    };
  }, [isAuthenticated, user?._id]);
};

export default useNativeAppListener;
