import { setupIonicReact } from '@ionic/react';
import { ThemeProvider as PalemttoComponentsThemeProvider, ResponsiveProvider } from '@palmetto/palmetto-components';
import { PrismicProvider } from '@prismicio/react';
import classNames from 'classnames';
import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import { client } from 'api/prismic/prismic';
import useDatadog from 'hooks/_app/useDatadog';
import useInAppReview from 'hooks/_app/useInAppReview';
import useAppListener from 'hooks/_app/useNativeAppListener';
import { usePushNotifications } from 'hooks/_app/usePushNotifications';
import useThemedFavicon from 'hooks/_app/useThemedFavicon';
import Header from '../../Header/Header';
import { LoadingPageProvider } from '../../LoadingPage/LoadingPage';
import { ModalProvider } from '../../Modal/Modal';
import Routes, { ROUTES } from '../../Routes/Routes';
import ApiProvider from '../../_app/ApiProvider/ApiProvider';
import AuthProvider from '../../_app/AuthProvider/AuthProvider';
import ErrorBoundary from '../../_app/ErrorBoundary/ErrorBoundary';
import ThemeProvider from '../../_app/ThemeProvider/ThemeProvider';
import TranslationProvider from '../../_app/TranslationProvider/TranslationProvider';
import CustomerTermsModal from '../../_modals/CustomerTermsModal/CustomerTermsModal';
import Tracking from '../../_tracking/Tracking';

import useAmplitudeExperiment from 'hooks/_app/useAmplitudeExperiment';
import { useArticleModal } from 'hooks/useArticleModal';
import ArticleModal from '../../ArticleModal/ArticleModal';
import { compareRoutes } from '../../Routes/compareRoutes';
import { CurrentPageProvider } from '../CurrentPageProvider/CurrentPageProvider';
import styles from './App.module.scss';

// always use iOS mode for Ionic
setupIonicReact({
  mode: 'ios',
});

export const App: React.FC = () => {
  useThemedFavicon();
  useInAppReview(true);
  useDatadog();
  usePushNotifications();
  useAppListener();
  useAmplitudeExperiment();

  const { pathname } = useLocation();

  const { articleModalState, dismiss } = useArticleModal();

  const routeConfig = ROUTES.find((route) => compareRoutes((route.path as string) || '', pathname));

  const showBackgroundImage = routeConfig?.hideBackgroundImage !== true;

  return (
    <div className={classNames(styles.pageContainer, { [styles.backgroundImage]: showBackgroundImage })}>
      <Header />
      <Routes />
      <CustomerTermsModal />
      {articleModalState.uid && (
        <ArticleModal uid={articleModalState.uid} isOpen={articleModalState.isOpen} onDismiss={dismiss} />
      )}
    </div>
  );
};

const ConnectedApp: React.FC = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <CurrentPageProvider>
        <ApiProvider>
          <AuthProvider>
            <TranslationProvider>
              <ResponsiveProvider>
                <PalemttoComponentsThemeProvider>
                  <ThemeProvider>
                    <LoadingPageProvider>
                      <ModalProvider>
                        <PrismicProvider client={client}>
                          <Tracking>
                            <App />
                          </Tracking>
                        </PrismicProvider>
                      </ModalProvider>
                    </LoadingPageProvider>
                  </ThemeProvider>
                </PalemttoComponentsThemeProvider>
              </ResponsiveProvider>
            </TranslationProvider>
          </AuthProvider>
        </ApiProvider>
      </CurrentPageProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
export default ConnectedApp;
