import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getFriendlyError } from '../../utils/error';

import { getBuildingAttributeHash } from './getBuildingHash';
import { getProperty } from './properties';
import { Property } from './types';

export interface UseProperties {
  isLoading: boolean;
  isFetched: boolean;
  data: Property | undefined;
  error?: string;
  refetch: () => void;
  buildingAttributeHash?: string;
}

export const QUERY_KEY_PROPERTIES = 'properties';

export const useProperties = (propertyId?: string): UseProperties => {
  const { data, error, isLoading, isFetched, refetch } = useQuery<Property | undefined, AxiosError>(
    [QUERY_KEY_PROPERTIES, propertyId],
    () => {
      if (propertyId) {
        return getProperty(propertyId);
      }
      return Promise.resolve(undefined);
    },
    { enabled: Boolean(propertyId) },
  );

  return {
    data,
    refetch,
    error: getFriendlyError(error, 'properties'),
    isLoading,
    isFetched,
    buildingAttributeHash: data && propertyId ? getBuildingAttributeHash(data, propertyId) : undefined,
  };
};

export default useProperties;
