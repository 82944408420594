import i18next, { TFunction } from 'i18next';
import jwtDecode from 'jwt-decode';
import React, { createContext, useContext } from 'react';
import { I18nextProvider } from 'react-i18next';

import { LocalizationLanguages } from 'api/user/types';
import { useUser } from 'api/user/useUser';
import i18n from 'i18next';
import { getUrlParam } from 'utils/url';

export interface LanguageContextShape {
  language: string;
  t: TFunction;
}

export const LanguageContext = createContext<LanguageContextShape>({
  language: 'en-US',
  t: () => undefined,
});

LanguageContext.displayName = 'LanguageContext';

const sanitizeLanguage = (language: string | undefined) =>
  language && (i18next?.options?.supportedLngs || []).indexOf(language) >= 0 ? language : undefined;

export interface TranslationProviderProps {
  children: React.ReactNode;
  language?: string;
}

const TranslationProvider: React.FC<TranslationProviderProps> = (props: TranslationProviderProps) => {
  const { children, language: languageFromProps } = props;

  const [language, setLanguage] = React.useState<string>(sanitizeLanguage(languageFromProps) || 'en-US');

  const { user } = useUser();

  const token = getUrlParam('token');

  React.useEffect(() => {
    let newLanguage: string | undefined;
    if (languageFromProps) {
      newLanguage = languageFromProps;
    } else if (token) {
      const { language: languageFromToken } = jwtDecode<{ language: keyof typeof LocalizationLanguages }>(token);
      if (languageFromToken) {
        newLanguage = String(languageFromToken);
      }
    } else if (user?.languageCode) {
      newLanguage = user.languageCode;
    }
    const sanitizedNewLanguage = sanitizeLanguage(newLanguage);
    if (sanitizedNewLanguage) {
      setLanguage(sanitizedNewLanguage);
    }
  }, [language, languageFromProps, user?.languageCode, token, setLanguage]);

  const value = React.useMemo(() => {
    return { language, t: i18next.getFixedT(language) };
  }, [language]);
  return (
    <LanguageContext.Provider value={value}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </LanguageContext.Provider>
  );
};

const useTranslation = (): LanguageContextShape => {
  return useContext(LanguageContext);
};

// export all react-i18next exports while overriding some
export * from 'react-i18next';
export { default as Trans } from './components/Trans/Trans'; // override Trans component
export { useTranslation }; // override useTranslation hook

export default TranslationProvider;
