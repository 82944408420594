import request from 'api/index';

import { ListResponse } from 'core/listResponse';
import { getFileExtensionFromUrl } from 'utils/getFileExtensionFromUrl';
import { fetchAndOpenDocument } from '../../utils/fetchAndOpenDocument';
import {
  ApiFinanceAccountDocumentResponse,
  ApiFinanceAccountOverview,
  ApiFinanceAccountUnionResponse,
} from './types';

export const FINANCE_ACCOUNT_ENDPOINT = '/finance-accounts';

export async function getFinanceAccount(financeAccountId: string): Promise<ApiFinanceAccountUnionResponse> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}/${financeAccountId}` });
  return result.data;
}

export async function getFinanceAccounts(): Promise<ListResponse<ApiFinanceAccountOverview>> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}` });
  return result.data;
}

export async function openStatementDocument(financeAccountId: string, statementLink: string, date: string) {
  const fileName = `statement-${date.split('T')[0]}.${getFileExtensionFromUrl(statementLink, 'pdf')}`;
  await openDocument(financeAccountId, statementLink, fileName);
}

export async function getFinanceAccountDocuments(
  financeAccountId: string,
): Promise<ApiFinanceAccountDocumentResponse> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}/${financeAccountId}/documents` });
  return result.data;
}

export async function openFinanceDocument(financeAccountId: string, targetUrl: string, name: string) {
  const fileName = `${name}.${getFileExtensionFromUrl(targetUrl, 'pdf')}`;
  await openDocument(financeAccountId, targetUrl, fileName);
}

async function openDocument(financeAccountId: string, targetUrl: string, fileName: string) {
  const url = `${FINANCE_ACCOUNT_ENDPOINT}/${financeAccountId}/document?targetUrl=${targetUrl}`;
  await fetchAndOpenDocument(url, fileName);
}
