import request from 'api';
import { RequestInterval } from 'api/energyAdvisor/types';
import { EnergyAdvisorInterval } from 'api/products/types';
import {
  GridConsumptionApiResponse,
  LifetimeImpactApiResponse,
  Property,
  PropertyEnergySavingsApiResponse,
  UpdateBuildingAttributes,
} from 'api/properties/types';
import { RatePlanEligibilityResponse, UtilityRatePlanBenchmarksResponse } from 'api/utilityRatePlan/types';

export const PROPERTIES_ENDPOINT = '/properties';

export const getProperty = async (propertyId: string): Promise<Property> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}` });
  return result.data;
};

export const getGridConsumption = async (interval: RequestInterval): Promise<GridConsumptionApiResponse> => {
  const result = await request({
    url: `${PROPERTIES_ENDPOINT}/grid-consumption`,
    params: interval,
  });
  return result.data;
};

export const updateBuildingAttributes = async ({
  propertyId,
  buildingAttributes,
}: UpdateBuildingAttributes): Promise<void> => {
  await request({
    method: 'PATCH',
    url: `${PROPERTIES_ENDPOINT}/${propertyId}/building`,
    data: { buildingAttributes },
  });
};

export const getUtilityRatePlanEligibility = async (propertyId: string): Promise<RatePlanEligibilityResponse> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}/rate-plan-eligibility` });
  return result.data;
};

export const getUtilityRatePlanBenchmarks = async (
  propertyId: string,
): Promise<UtilityRatePlanBenchmarksResponse> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}/rate-plan-benchmarks` });
  return result.data;
};

export const getLifetimeImpact = async (propertyId: string): Promise<LifetimeImpactApiResponse> => {
  const result = await request({ url: `${PROPERTIES_ENDPOINT}/${propertyId}/lifetime-impact` });
  return result.data;
};

export const getPropertyEnergyBaselines = async (
  propertyId: string,
  interval?: RequestInterval,
  buildingAttributeHash?: string,
): Promise<EnergyAdvisorInterval[]> => {
  const result = await request({
    url: `${PROPERTIES_ENDPOINT}/${propertyId}/energy-baselines`,
    params: { ...interval, buildingAttributeHash },
  });
  return result.data;
};

export const getPropertyEnergySavings = async (
  propertyId: string,
  interval: RequestInterval,
  categoryIds: string,
  buildingAttributeHash?: string,
): Promise<PropertyEnergySavingsApiResponse> => {
  const result = await request({
    url: `${PROPERTIES_ENDPOINT}/${propertyId}/energy-savings`,
    params: { ...interval, categoryIds, buildingAttributeHash },
  });
  return result.data;
};
